<template>
	<div id="newsdetailpage" class="newsdetailpage pageContainer container">
		<!-- <h1 class="pageTitle" :aria-label="$t('home-title-page')">
			{{ $t("home-title-page-split1")}}
			<span>{{ $t("home-title-page-split2") }}</span>
		</h1> -->

		<div class="parallaxContainerNews row">
			<div class="col-12 col-md-7 parallaxGroup1 order-1 order-sm-2">
				<div v-for="(image, index) in imageGroup1.slice(0, 3)" :class="['rellax', image.addclass, 'imageParallax' + (index + 1)]" :data-rellax-speed="image.speed">
					<img
					 
					:key="image.id"
					:src="image.src"
					:alt="image.alt"
					
					
					importance="high"
					/>
				</div>
				
			</div>

			<div class="pageContentText col-12 col-md-5 order-md-2">
				<h1 v-html="news.title">{{ news.title }}</h1>
				<p v-html="news.content">{{ news.content }}</p>
				<p class="newsDate">{{ news.pubdata }}</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "newsdetailpage",
		data() {
			return {
				news: "",
				imageGroup1: [],
				hasImage: false,
				rellax: null
			};
		},
		created() {
			let selectedNews = this.$route.params.id;

			this.$http
				.get(
					"https://www.bstone.pt/webservices/" +
						this.$i18n.locale +
						"/news-detail2?id=" +
						selectedNews
				)
				.then(response => {
					this.news = response.data;
					this.imageGroup1 = response.data.gallery1;
					this.hasImage = true
					
					this.$nextTick(() => {
                		this.rellax = new Rellax('.rellax', { speed: 1 })
						this.$eventBus.$emit("pageFinishLoad", true);
            		})
				});
		},
		watch: {
			$route(to, from) {
				if (this.hasImage) this.rellax.refresh()
			}
		}
	};
</script>

<style lang="scss">
	@media (min-width: 1200px){
		.container-xl, .container-lg, .container-md, .container-sm, .newsdetailpage.container {
			max-width: 1140px;
		}
	}
#newsdetailpage .imageParallax1{
	float: left !important;
	/*height: 450px;
	width: 50%;*/
}
#newsdetailpage .imageParallax1 img{
	object-fit: cover;
    width: 450px;
    height: 450px;
}

#newsdetailpage .imageParallax2{
	float: right !important;
	z-index: 3;
	top: -5%;
	/*height: 450px;
	width: 50%;*/
}
#newsdetailpage .imageParallax2 img{
	object-fit: cover;
    width: 450px !important;
    height: 600px !important;
}
#newsdetailpage .imageParallax3 img{
	object-fit: cover;
    width: 450px !important;
    height: 450px !important;
}
#newsdetailpage .parallaxGroup1 img{
    width: 100%;
    height: auto;
    margin: 0 auto;
    position: relative!important;
    
     &.imageParallax1{
        z-index: 1;
        right: 10%;
        top: 40%;
    }

    &.imageParallax2{
        z-index: 3;
        top: 80%; 
        left: 10%;
    }

    &.imageParallax3{
        z-index: 2;
        top: 20%;
        left: 25%;
    }   
}
	#newsdetailpage {
		margin-top: 140px;

		h1.pageTitle,
		.h1.pageTitle {
			position: fixed;
		}

		.parallaxContainerNewsparallaxContainerNews {
			padding-top: 150px;
			padding-bottom: 270px;

			.parallaxGroup1 {
				padding-right: 11%;

				img {
					position: relative;
					-webkit-filter: grayscale(0%);
					-moz-filter: grayscale(0%);
					filter: grayscale(0%);
					z-index: 0;
					right: 0;
					left: 0;
					top: 0;
					margin-bottom: 5px;
				}
			}
		}

		.pageContentText {
			// height: calc(100vh - 160px);
			// position: -webkit-sticky;
			// position: sticky;
			//top: 160px;
			right: 5%;
			margin: 0;
			padding: 0;

			h1{
				margin-top:15%;
			}
			.newsDate {
				position: relative;
				bottom: 0;
			}
		}
	}

	
	#newsdetailpage .parallaxGroup1 img{
    width: 100%;
    height: auto;
    margin: 0 auto;
    position: relative!important;
    
    /* &.imageParallax1{
        z-index: 1;
        right: 10%;
        top: 40%;
    }

    &.imageParallax2{
        z-index: 3;
        top: 80%; 
        left: 10%;
    }

    &.imageParallax3{
        z-index: 2;
        top: 20%;
        left: 25%;
    }   */
}
.mini-title{
	text-transform: uppercase;
	font-size: 15px;
	color: #c47c5a;
	font-family: 'Oswald';
	letter-spacing: 1.2px;
}
#newsdetailpage .imageParallax1{
	float: left !important;
	/*height: 450px;
	width: 50%;*/
}
#newsdetailpage .imageParallax1 img{
	object-fit: cover;
    width: 450px;
    height: 450px;
}

#newsdetailpage .imageParallax2{
	float: right !important;
	right: 70px;
	z-index: 3;
	top: 0%;
	/*height: 450px;
	width: 50%;*/
}
#newsdetailpage .imageParallax2 img{
	object-fit: cover;
    width: 370px !important;
    height: 600px !important;
}
#newsdetailpage .imageParallax3 img{
	object-fit: cover;
    width: 450px !important;
    height: 450px !important;
}

	#newsdetailpage {
		margin-top: 140px;

		h1.pageTitle,
		.h1.pageTitle {
			position: fixed;
		}

		.parallaxContainerNews {
			padding-top: 150px;
			// padding-bottom: 270px;

			.parallaxGroup1 {
				padding-right: 11%;

				img {
					position: relative;
					-webkit-filter: grayscale(0%);
					-moz-filter: grayscale(0%);
					filter: grayscale(0%);
					z-index: 0;
					right: 0;
					left: 0;
					top: 0;
					margin-bottom: 5px;
				}
			}
		}

		.pageContentText {
			height: calc(100vh - 160px);
			position: -webkit-sticky;
			position: sticky;
			/*top: 150px;*/
			right: 5%;
			margin: 0;
			padding: 0;

			h1{
				margin-top:15%;
			}
			.newsDate {
				position: relative;
				bottom: 0;
			}
		}
	}

	@media (max-width: 990px){
		#newsdetailpage .rellax {
			transform: none !important;
		}
		#newsdetailpage .col-sm-8{
			max-width: 100%;
		}
		#newsdetailpage .imageParallax1 img, .imageParallax2 img, .imageParallax3 img{
			width: 100% !important;
			height: auto !important;
		}
		#newsdetailpage .imageParallax2 {
			float: none !important; 
			right: 0px; 
			z-index: 3; 
			top: 0%;
		}
		#newsdetailpage .parallaxContainerProjects .parallaxGroup1{
			padding-right: 0;
			padding-left: 0;			
		}
		#newsdetailpage .pageContentText {
			right: 0%; 
			margin: 0; 
			padding: inherit;   
			padding-right: 15px;
    		padding-left: 15px; 
			padding-top: 0;
		}
		#newsdetailpage .parallaxContainerProjects {
			padding-top: 50px;
		}
		#newsdetailpage{
			padding-bottom: 150px;
		}
	}
</style>
